// src/App.jsx
import React, { useState } from 'react';
import ChatInterface from './components/ChatInterface';
import AdminPanel from './components/AdminPanel';

function App() {
  const [activePanel, setActivePanel] = useState('chat');
  const [chatHistory, setChatHistory] = useState([
    {
      role: 'assistant',
      content: "Hi! I'm an AI assistant that can tell you about my professional background. Feel free to ask about my experience, skills, or specific roles!"
    }
  ]);

  // Function to update chat history that we'll pass to ChatInterface
  const updateChatHistory = (newMessages) => {
    setChatHistory(newMessages);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white shadow mb-4">
        <div className="max-w-7xl mx-auto px-4 py-2 flex justify-between items-center">
          <h1 className="text-xl font-bold">CV Chatbot</h1>
          <button
            onClick={() => setActivePanel(activePanel === 'chat' ? 'admin' : 'chat')}
            className={`px-4 py-2 text-sm rounded ${activePanel === 'admin'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-100 hover:bg-gray-200'
              }`}
          >
            {activePanel === 'chat' ? 'Admin Panel' : 'Back to Chat'}
          </button>
        </div>
      </nav>

      <div style={{ display: activePanel === 'chat' ? 'block' : 'none' }}>
        <ChatInterface
          messages={chatHistory}
          onUpdateMessages={updateChatHistory}
        />
      </div>

      <div style={{ display: activePanel === 'admin' ? 'block' : 'none' }}>
        <AdminPanel />
      </div>
    </div>
  );
}

export default App;