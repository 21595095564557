  const config = {
    development: {
      apiUrl: import.meta.env.VITE_API_URL || 'http://localhost:3000',
    },
    production: {
      apiUrl: import.meta.env.VITE_API_URL,
    }
  };
  
  const environment = import.meta.env.MODE || 'development';
  export default config[environment];