// src/components/CVUpload.jsx
import React, { useState } from 'react';
import config from '../config';

import { 
  Upload, 
  FileText, 
  CheckCircle, 
  XCircle, 
  AlertCircle,
  Loader, 
  FileUp,
  Brain,
  Database,
  Save,
  Edit2
} from 'lucide-react';
import { 
  PrimaryButton, 
  SecondaryButton,
  DangerButton,
  Card, 
  CardHeader, 
  CardContent,
  FormInput,
  FormTextArea 
} from './shared/FormStyles';

const ProcessingStep = ({ title, description, status, icon: Icon }) => (
  <div className={`flex items-start p-4 rounded-lg border ${
    status === 'complete' ? 'bg-green-50 border-green-200' :
    status === 'error' ? 'bg-red-50 border-red-200' :
    status === 'processing' ? 'bg-blue-50 border-blue-200' :
    'bg-gray-50 border-gray-200'
  }`}>
    <div className="flex-shrink-0 mt-1">
      {status === 'complete' ? (
        <CheckCircle className="w-5 h-5 text-green-500" />
      ) : status === 'error' ? (
        <XCircle className="w-5 h-5 text-red-500" />
      ) : status === 'processing' ? (
        <Loader className="w-5 h-5 text-blue-500 animate-spin" />
      ) : (
        <Icon className="w-5 h-5 text-gray-400" />
      )}
    </div>
    <div className="ml-4">
      <h4 className={`text-sm font-medium ${
        status === 'complete' ? 'text-green-800' :
        status === 'error' ? 'text-red-800' :
        status === 'processing' ? 'text-blue-800' :
        'text-gray-800'
      }`}>
        {title}
      </h4>
      <p className={`mt-1 text-sm ${
        status === 'complete' ? 'text-green-600' :
        status === 'error' ? 'text-red-600' :
        status === 'processing' ? 'text-blue-600' :
        'text-gray-600'
      }`}>
        {description}
      </p>
    </div>
  </div>
);

const ValidationError = ({ errors }) => (
    <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
      <div className="flex items-center mb-2">
        <AlertCircle className="w-5 h-5 text-red-500 mr-2" />
        <h3 className="text-sm font-medium text-red-800">
          Validation Errors Found
        </h3>
      </div>
      <ul className="list-disc list-inside text-sm text-red-600 ml-2">
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );
  
  const ExtractedRoleCard = ({ role, onEdit, onValidate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedRole, setEditedRole] = useState(role);
    const [validationErrors, setValidationErrors] = useState([]);
  
    const validateRole = () => {
      const errors = [];
      if (!editedRole.company?.trim()) errors.push("Company name is required");
      if (!editedRole.role?.trim()) errors.push("Role title is required");
      if (!editedRole.startDate?.trim()) errors.push("Start date is required");
      if (!editedRole.description?.trim()) errors.push("Description is required");
      
      const startDate = new Date(editedRole.startDate);
      if (editedRole.endDate && new Date(editedRole.endDate) < startDate) {
        errors.push("End date cannot be before start date");
      }
      
      setValidationErrors(errors);
      return errors.length === 0;
    };
  
    const handleSave = () => {
      if (validateRole()) {
        onEdit(editedRole);
        setIsEditing(false);
        onValidate(true);
      }
    };
  
    return (
      <div className="border border-gray-200 rounded-lg p-4 bg-white">
        {!isEditing ? (
          <>
            <div className="flex justify-between items-start mb-4">
              <div>
                <h4 className="font-medium text-gray-900">{editedRole.role}</h4>
                <p className="text-sm text-gray-500">{editedRole.company}</p>
                <p className="text-sm text-gray-500">
                  {editedRole.startDate} - {editedRole.endDate || 'Present'}
                </p>
              </div>
              <SecondaryButton onClick={() => setIsEditing(true)}>
                <Edit2 size={16} className="mr-2" />
                Edit
              </SecondaryButton>
            </div>
            <p className="text-sm text-gray-600 mb-3">{editedRole.description}</p>
            {editedRole.skills && (
              <div className="flex flex-wrap gap-2">
                {editedRole.skills.map((skill, i) => (
                  <span key={i} className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
                    {skill}
                  </span>
                ))}
              </div>
            )}
          </>
        ) : (
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <FormInput
                label="Company"
                value={editedRole.company}
                onChange={(e) => setEditedRole({...editedRole, company: e.target.value})}
                required
              />
              <FormInput
                label="Role"
                value={editedRole.role}
                onChange={(e) => setEditedRole({...editedRole, role: e.target.value})}
                required
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <FormInput
                label="Start Date"
                type="month"
                value={editedRole.startDate}
                onChange={(e) => setEditedRole({...editedRole, startDate: e.target.value})}
                required
              />
              <FormInput
                label="End Date"
                type="month"
                value={editedRole.endDate || ''}
                onChange={(e) => setEditedRole({...editedRole, endDate: e.target.value})}
              />
            </div>
            <FormTextArea
              label="Description"
              value={editedRole.description}
              onChange={(e) => setEditedRole({...editedRole, description: e.target.value})}
              rows={3}
              required
            />
            <FormInput
              label="Skills (comma-separated)"
              value={editedRole.skills.join(', ')}
              onChange={(e) => setEditedRole({
                ...editedRole, 
                skills: e.target.value.split(',').map(s => s.trim()).filter(Boolean)
              })}
            />
            
            {validationErrors.length > 0 && (
              <ValidationError errors={validationErrors} />
            )}
  
            <div className="flex justify-end gap-2">
              <SecondaryButton onClick={() => setIsEditing(false)}>
                Cancel
              </SecondaryButton>
              <PrimaryButton onClick={handleSave}>
                Save Changes
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    );
  };

const CVUpload = ({ onUploadComplete }) => {
  const [file, setFile] = useState(null);
  const [processingSteps, setProcessingSteps] = useState({
    upload: 'waiting',    // waiting, processing, complete, error
    extract: 'waiting',   // waiting, processing, complete, error
    analyze: 'waiting',   // waiting, processing, complete, error
    complete: 'waiting'   // waiting, processing, complete, error
  });
  const [extractedData, setExtractedData] = useState(null);
  const [error, setError] = useState(null);
  const [extractedRoles, setExtractedRoles] = useState([]);
  const [validatedRoles, setValidatedRoles] = useState([]);
  const [isCreatingRoles, setIsCreatingRoles] = useState(false);
  const [roleCreationErrors, setRoleCreationErrors] = useState([]);

  const validateExtractedData = (data) => {
    const errors = [];
    if (!data.roles || !Array.isArray(data.roles)) {
      errors.push("No valid roles were extracted from the CV");
      return errors;
    }
    
    if (data.roles.length === 0) {
      errors.push("No roles were found in the CV");
      return errors;
    }

    return errors;
  };

  const formatDateForMongoDB = (dateString) => {
    if (!dateString || dateString === 'Present') return null;
    
    // Handle MM/YYYY format
    if (dateString.includes('/')) {
      const [month, year] = dateString.split('/');
      return `${year}-${month.padStart(2, '0')}-01`;
    }
    
    // Handle YYYY-MM format (if it's already in that format)
    if (dateString.includes('-')) {
      return `${dateString}-01`;
    }
    
    return null;
  };

  const createRoles = async () => {
    setIsCreatingRoles(true);
    setRoleCreationErrors([]);
    const errors = [];
  
    try {
      console.log('Starting role creation for:', extractedRoles);
  
      for (const role of extractedRoles) {
        // Format the role data with proper date handling
        const formattedRole = {
          company: role.company,
          role: role.role,
          startDate: formatDateForMongoDB(role.startDate),
          endDate: formatDateForMongoDB(role.endDate),
          isCurrent: !role.endDate || role.endDate === 'Present',
          description: role.description,
          skills: Array.isArray(role.skills) ? role.skills : [],
          achievements: Array.isArray(role.achievements) ? role.achievements : [],
          keywords: Array.isArray(role.skills) ? role.skills : []
        };
  
        console.log('Sending formatted role:', formattedRole);
  
        const response = await fetch(`${config.apiUrl}/api/roles`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formattedRole)
        });
  
        const result = await response.json();
  
        if (!response.ok) {
          errors.push(`Failed to create role ${role.role}: ${result.error || 'Unknown error'}`);
          console.error('Role creation failed:', result);
        } else {
          console.log('Role created successfully:', result);
        }
      }
  
      if (errors.length === 0) {
        onUploadComplete({ 
          success: true, 
          rolesCreated: extractedRoles.length 
        });
        setSuccessMessage(`Successfully created ${extractedRoles.length} roles`);
        setExtractedRoles([]);
        setValidatedRoles([]);
        setFile(null);
      } else {
        setRoleCreationErrors(errors);
      }
    } catch (error) {
      console.error('Role creation error:', error);
      setRoleCreationErrors([`Network error: ${error.message}`]);
    } finally {
      setIsCreatingRoles(false);
    }
  };

  const [successMessage, setSuccessMessage] = useState(null);

  const updateStep = (step, status) => {
    setProcessingSteps(prev => ({ ...prev, [step]: status }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && (
      selectedFile.type === 'application/pdf' ||
      selectedFile.type === 'application/msword' ||
      selectedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )) {
      setFile(selectedFile);
      setError(null);
      // Reset steps when new file is selected
      Object.keys(processingSteps).forEach(step => updateStep(step, 'waiting'));
      setExtractedData(null);
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    try {
      // Upload step
      updateStep('upload', 'processing');
      const formData = new FormData();
      formData.append('cv', file);
      
      // Simulate network delay for demo purposes
      await new Promise(resolve => setTimeout(resolve, 1500));
      updateStep('upload', 'complete');

      // Text extraction step
      updateStep('extract', 'processing');
      await new Promise(resolve => setTimeout(resolve, 2000));
      updateStep('extract', 'complete');

      // AI Analysis step
      updateStep('analyze', 'processing');
      const response = await fetch(`${config.apiUrl}/api/cv/upload`, {
        method: 'POST',
        body: formData
      });

      const data = await response.json();
      
        if (response.ok) {
        const validationErrors = validateExtractedData(data);
        if (validationErrors.length > 0) {
          setError(validationErrors.join('. '));
          updateStep('analyze', 'error');
          return;
        }

        updateStep('analyze', 'complete');
        setExtractedRoles(data.roles);
        setValidatedRoles(new Array(data.roles.length).fill(false));
      } else {
        throw new Error(data.error || 'Failed to process CV');
      }
    } catch (error) {
      console.error('Upload error:', error);
      setError(error.message);
      Object.keys(processingSteps).forEach(step => {
        if (processingSteps[step] === 'processing') {
          updateStep(step, 'error');
        }
      });
    }
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader 
          title="Upload CV" 
          subtitle="Upload your CV to automatically extract role information"
        />
        <CardContent>
          <div className="space-y-6">
            {/* Upload Area */}
            <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center hover:border-gray-400 transition-colors">
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
                className="hidden"
                id="cv-upload"
              />
              <label 
                htmlFor="cv-upload"
                className="cursor-pointer flex flex-col items-center"
              >
                <FileUp size={40} className="text-gray-400 mb-4" />
                <span className="text-sm font-medium text-gray-700">
                  Drop your CV here or click to browse
                </span>
                <span className="text-xs text-gray-500 mt-2">
                  Supports PDF, DOC, and DOCX files
                </span>
              </label>
            </div>

            {/* Selected File */}
            {file && (
              <div className="flex items-center justify-between p-4 bg-white border border-gray-200 rounded-lg">
                <div className="flex items-center">
                  <FileText size={20} className="text-gray-500 mr-2" />
                  <div>
                    <p className="text-sm font-medium text-gray-700">{file.name}</p>
                    <p className="text-xs text-gray-500">
                      {(file.size / 1024 / 1024).toFixed(2)} MB
                    </p>
                  </div>
                </div>
                <PrimaryButton
                  onClick={handleUpload}
                  disabled={Object.values(processingSteps).some(step => step === 'processing')}
                >
                  <Upload size={16} className="mr-2" />
                  Process CV
                </PrimaryButton>
              </div>
            )}

            {/* Processing Steps */}
            {file && (
              <div className="space-y-3">
                <ProcessingStep
                  title="Upload CV"
                  description="Uploading your CV file to the server"
                  status={processingSteps.upload}
                  icon={FileUp}
                />
                <ProcessingStep
                  title="Extract Content"
                  description="Extracting text content from your CV"
                  status={processingSteps.extract}
                  icon={FileText}
                />
                <ProcessingStep
                  title="AI Analysis"
                  description="Analyzing content to identify roles and experience"
                  status={processingSteps.analyze}
                  icon={Brain}
                />
                <ProcessingStep
                  title="Process Complete"
                  description="Preparing extracted data for review"
                  status={processingSteps.complete}
                  icon={Database}
                />
              </div>
            )}

            {/* Error Message */}
            {error && (
              <div className="p-4 bg-red-50 border border-red-200 rounded-lg flex items-start">
                <AlertCircle className="w-5 h-5 text-red-500 mt-0.5" />
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    Error Processing CV
                  </h3>
                  <p className="mt-1 text-sm text-red-600">{error}</p>
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Extracted Data Preview */}
      {extractedData && (
        <Card>
          <CardHeader 
            title="Extracted Information" 
            subtitle="Review the information extracted from your CV"
          />
          <CardContent>
            <div className="space-y-4">
              {extractedData.roles.map((role, index) => (
                <div 
                  key={index}
                  className="p-4 bg-white border border-gray-200 rounded-lg"
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h4 className="font-medium text-gray-900">
                        {role.role}
                      </h4>
                      <p className="text-sm text-gray-500">
                        {role.company}
                      </p>
                    </div>
                    <span className="text-sm text-gray-500">
                      {role.startDate} - {role.endDate || 'Present'}
                    </span>
                  </div>
                  <p className="mt-2 text-sm text-gray-600">
                    {role.description}
                  </p>
                  {role.skills && (
                    <div className="mt-2 flex flex-wrap gap-2">
                      {role.skills.map((skill, i) => (
                        <span 
                          key={i}
                          className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full"
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
        
      )}
       {extractedRoles.length > 0 && (
        <Card>
          <CardHeader 
            title="Validate Extracted Roles" 
            subtitle="Review and edit the extracted roles before creating them"
          />
          <CardContent>
            <div className="space-y-6">
              {extractedRoles.map((role, index) => (
                <ExtractedRoleCard
                  key={index}
                  role={role}
                  onEdit={(updatedRole) => {
                    const newRoles = [...extractedRoles];
                    newRoles[index] = updatedRole;
                    setExtractedRoles(newRoles);
                  }}
                  onValidate={(isValid) => {
                    const newValidated = [...validatedRoles];
                    newValidated[index] = isValid;
                    setValidatedRoles(newValidated);
                  }}
                />
              ))}

              {roleCreationErrors.length > 0 && (
                <ValidationError errors={roleCreationErrors} />
              )}

                <div className="flex justify-end">
                <PrimaryButton
                    onClick={createRoles}
                    disabled={isCreatingRoles}
                    className="flex items-center"
                >
                    {isCreatingRoles ? (    
                    <>
                        <Loader className="animate-spin -ml-1 mr-2 h-5 w-5" />
                        Creating Roles...
                    </>
                    ) : (
                    <>
                        <Save className="w-5 h-5 mr-2" />
                        Create Roles
                    </>
                    )}
                </PrimaryButton>
                </div>
            </div>
          </CardContent>
        </Card>
      )}
      {successMessage && (
        <div className="p-4 bg-green-50 border border-green-200 rounded-lg flex items-center">
            <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
            <span className="text-green-700">{successMessage}</span>
        </div>
        )}
        {roleCreationErrors.length > 0 && (
        <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-lg">
            <div className="flex items-center mb-2">
            <AlertCircle className="w-5 h-5 text-red-500 mr-2" />
            <h3 className="text-sm font-medium text-red-800">
                Errors occurred while creating roles
            </h3>
            </div>
            <ul className="list-disc list-inside text-sm text-red-600">
            {roleCreationErrors.map((error, index) => (
                <li key={index}>{error}</li>
            ))}
            </ul>
        </div>
        )}
    </div>
  );
};

export default CVUpload;