// src/components/AdminPanel.jsx
import React, { useState } from 'react';
import RoleSection from './RoleSection';
import ProjectSection from './ProjectSection';
import CVUpload from './CVUpload';
import SkillsSection from './SkillsSection';
import {
  Card,
  CardHeader,
  CardContent,
  SecondaryButton
} from './shared/FormStyles';
import { Briefcase, FolderKanban, Upload, GraduationCap } from 'lucide-react';


const EnvironmentInfo = () => {
  const [showVars, setShowVars] = useState(false);
 
  // Get all environment variables from import.meta.env
  const getAllEnvVars = () => {
    const envVars = {};
    for (const key in import.meta.env) {
      // Optionally filter out Vite's internal vars
      // if (!key.startsWith('VITE_INTERNAL')) {
      envVars[key] = import.meta.env[key];
      // }
    }
    return envVars;
  };
 
  return (
    <Card className="mb-6">
      <CardHeader 
        title="Environment Configuration"
        subtitle="Current environment settings and variables"
      />
      <CardContent>
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium">Environment Variables</h3>
            <SecondaryButton onClick={() => setShowVars(!showVars)}>
              {showVars ? 'Hide' : 'Show'} Variables
            </SecondaryButton>
          </div>
 
          {showVars && (
            <div className="bg-gray-50 p-4 rounded-lg">
              <dl className="space-y-2">
                {Object.entries(getAllEnvVars()).map(([key, value]) => (
                  <div key={key} className="flex justify-between items-start break-all">
                    <dt className="font-medium mr-4 flex-shrink-0">{key}:</dt>
                    <dd className="text-right">{
                      // Handle different types of values
                      typeof value === 'object' 
                        ? JSON.stringify(value)
                        : String(value)
                    }</dd>
                  </div>
                ))}
              </dl>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
 };



const AdminPanel = () => {
  const [activeTab, setActiveTab] = useState('cv'); // 'cv', 'roles', 'projects', 'skills'
  return (
    <div className="min-h-screen bg-gray-50 pb-12">
      <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          CV Management Dashboard
        </h1>

        {/* Navigation Tabs */}
        <div className="flex border-b border-gray-200 mb-8">
          <button // CV Upload
            onClick={() => setActiveTab('cv')}
            className={`flex items-center px-6 py-3 text-sm font-medium border-b-2 -mb-px ${activeTab === 'cv'
              ? 'border-blue-500 text-blue-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
          >
            <Upload size={18} className="mr-2" />
            CV Upload
          </button>
          <button // Roles
            onClick={() => setActiveTab('roles')}
            className={`flex items-center px-6 py-3 text-sm font-medium border-b-2 -mb-px ${activeTab === 'roles'
              ? 'border-blue-500 text-blue-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
          >
            <Briefcase size={18} className="mr-2" />
            Roles
          </button>
          <button // Projects
            onClick={() => setActiveTab('projects')}
            className={`flex items-center px-6 py-3 text-sm font-medium border-b-2 -mb-px ${activeTab === 'projects'
              ? 'border-blue-500 text-blue-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
          >
            <FolderKanban size={18} className="mr-2" />
            Projects
          </button>
          <button // skills
            onClick={() => setActiveTab('skills')}
            className={`flex items-center px-6 py-3 text-sm font-medium border-b-2 -mb-px ${activeTab === 'skills'
              ? 'border-blue-500 text-blue-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
          >
            <GraduationCap size={18} className="mr-2" />
            Skills
          </button>
        </div>

        {/* Content Sections */}
        {activeTab === 'cv' && (
          <div className="max-w-3xl mx-auto">
            <CVUpload
              onUploadComplete={(data) => {
                // Handle the extracted data
                console.log('Extracted CV data:', data);
                // You could automatically switch to roles tab and create roles
                setActiveTab('roles');
              }}
            />
          </div>
        )}

        {activeTab === 'roles' && (
          <Card>
            <CardHeader
              title="Manage Roles"
              subtitle="Add and manage your employment history"
            />
            <CardContent>
              <RoleSection />
            </CardContent>
          </Card>
        )}

        {activeTab === 'projects' && (
          <Card>
            <CardHeader
              title="Manage Projects"
              subtitle="Add and manage your client projects"
            />
            <CardContent>
              <ProjectSection />
            </CardContent>
          </Card>
        )}

        {activeTab === 'skills' && (
          <Card>
            <CardHeader
              title="Manage Skills"
              subtitle="Add and manage your skills"
            />
            <CardContent>
              <SkillsSection />
            </CardContent>
          </Card>
        )}
      </div>

      <EnvironmentInfo />
      
      {/* Footer Info */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center text-sm text-gray-500">
          <div>
            CV Chatbot Admin Dashboard
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setActiveTab('cv')}
              className="hover:text-gray-700"
            >
              Upload CV
            </button>
            <button
              onClick={() => setActiveTab('roles')}
              className="hover:text-gray-700"
            >
              Manage Roles
            </button>
            <button
              onClick={() => setActiveTab('projects')}
              className="hover:text-gray-700"
            >
              Manage Projects
            </button>
            <button
              onClick={() => setActiveTab('skills')}
              className="hover:text-gray-700"
            >
              Manage Skills
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;